import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";

const Result = () => {
  return (
    <>
      <p>Thank you for subcribing to our Newsletter!</p>
    </>
  );
};

const Footer = () => {
  const form = useRef();
  const [result, showResult] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_s5woxgp",
        "template_bn5vtub",
        form.current,
        "UDqynssnARp4qIk4r"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
    showResult(true);
  };

  setTimeout(() => {
    showResult(false);
  }, 5000);
  return (
    <div>
      {/* <!-- Footer section Start--> */}
      <section class="footer_wrapper mt-3 mt-md-0">
        <div class="container px-5 px-lg-0">
          <div class="row">
            <div class="col-lg-3 col-sm-6 mb-5 mb-lg-0">
              <h5>Starwood & Iron Crafts</h5>
              <p class="mb-4 ps-0 company_details">
                We specialize in Construction Services and Home Renovation and
                Repairs, striving for 100% customer satisfaction delivering top
                notch quality in our craft.
              </p>
              <div class="contact-info">
                <ul class="list-unstyled">
                  <li>
                    <a href="">
                      <i class="fa fa-home me-3"></i> Claremont, Cape Town, 7708
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i class="fa fa-phone me-3"></i>+2772 728 8729
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i class="fa fa-envelope me-3"></i>
                      info@starwoodandironcrafts.co.za
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 mb-5 mb-lg-0">
              <h5>Customer Support</h5>
              <ul class="link-widget p-0">
                <li>
                  <a href="">Privacy Policy</a>
                </li>
                <li>
                  <a href="">Terms & Conditions</a>
                </li>
                {/* <li><a href="#">Product Returns</a></li> */}
                {/* <li><a href="#">Wholesale Policy</a></li> */}
              </ul>
            </div>
            <div class="col-lg-3 col-sm-6 mb-5 mb-lg-0">
              <h5>Quick Links</h5>
              <ul class="link-widget p-0">
                <li>
                  <Link to="/aboutus">About Us</Link>
                </li>
                <li>
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <Link to="/projects" href="/projects">
                    Projects
                  </Link>
                </li>
                {/* <li><a href="#">Terms & Conditions</a></li> */}
                <li>
                  <Link to="/contacts" href="/contacts">
                    Contact
                  </Link>
                </li>
                {/* <li><a href="#">Term Of Use</a></li> */}
              </ul>
            </div>
            <div class="col-lg-3 col-sm-6 mb-5 mb-lg-0">
              <h5>Newsletter</h5>
              <form ref={form} action="" onSubmit={sendEmail}>
                <div class="form-group mb-4">
                  <input
                    name="subscribe"
                    type="email"
                    class="form-control bg-transparent"
                    placeholder="Enter Your Email Here"
                    required
                  />
                  <button type="submit" class="btn main-btn">
                    Subscribe
                  </button>
                  <div className="row">{result ? <Result /> : null}</div>
                </div>
              </form>
              <h5>Stay Connected</h5>
              <ul class="social-network d-flex align-items-center p-0">
                <li>
                  <a href="">
                    <i class="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="">
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="">
                    <i class="fab fa-linkedin-in"></i>
                  </a>
                </li>
                <li>
                  <a href="">
                    <i class="fab fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="container-fluid copyright-section">
          <p>
            Copyright <a href="#">© RavenMatrixx.</a> All Rights Reserved
          </p>
        </div>
      </section>
      {/* <!-- Footer Section Exit  --> */}
    </div>
  );
};

export default Footer;
