import React from "react";
import { Routes, Route } from "react-router-dom";
import Abouts from "./routes/Abouts";
import Contacts from "./routes/Contacts";
import Errors from "./routes/Errors";
import Projects from "./routes/Projects";
import Service from "./routes/Service";
import Main from "./routes/Main";
import "./App.css";
import "./styles/Styles.css";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/aboutus" element={<Abouts />} />
        <Route path="/errors" element={<Errors />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/services" element={<Service />} />
        <Route path="/contacts" element={<Contacts />} />
      </Routes>
    </>
  );
}

export default App;
