import React from "react";
import Navbar from "../common/navBar/Navbar";
import Footer from "../common/footer/Footer";
import Product from "../components/product/Product";

const Service = () => {
  return (
    <div>
      <Navbar />
      <Product />
      <Footer />
    </div>
  );
};

export default Service;
