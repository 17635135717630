import React from "react";
import image1 from "../../assets/images/gallery/1.jpeg";
import image2 from "../../assets/images/gallery/2.jpeg";
import image3 from "../../assets/images/gallery/3.jpeg";
import image4 from "../../assets/images/gallery/4.jpeg";
import image5 from "../../assets/images/gallery/5.jpeg";
import image6 from "../../assets/images/gallery/1.jpeg";
import image7 from "../../assets/images/gallery/2.jpeg";
import image8 from "../../assets/images/gallery/3.jpeg";

const Gallery = () => {
  return (
    <div class="gallery">
      {/* <!-- banner Section start --> */}
      <section class="banner_section">
        <div class="container">
          <div class="banner-content">
            <h1>Projects</h1>
          </div>
        </div>
      </section>
      {/* <!-- banner section exit --> */}

      {/* <!-- Gallery Section Start--> */}
      <section class="gallery_section">
        <div class="container">
          <div class="row">
            <div class="col-12 text-center pb-5">
              <h2 class="section-title">Our Top Projects</h2>
              <p class="section-subtitle">Beauty from within and out</p>
            </div>
            <div class="col-sm-6 col-lg-4 mb-4">
              <a href={image1} data-lightbox="image" data-title="Lemon">
                <img
                  decoding="async"
                  src={image1}
                  class="img-fluid rounded-3"
                />
              </a>
            </div>
            <div class="col-sm-6 col-lg-4 mb-4">
              <a href={image2} data-lightbox="image" data-title="Lemon">
                <img
                  decoding="async"
                  src={image2}
                  class="img-fluid rounded-3"
                />
              </a>
            </div>
            <div class="col-sm-6 col-lg-4 mb-4">
              <a href={image3} data-lightbox="image" data-title="Lemon">
                <img
                  decoding="async"
                  src={image3}
                  class="img-fluid rounded-3"
                />
              </a>
            </div>
            <div class="col-sm-6 col-lg-4 mb-4">
              <a href={image4} data-lightbox="image" data-title="Lemon">
                <img
                  decoding="async"
                  src={image4}
                  class="img-fluid rounded-3"
                />
              </a>
            </div>
            <div class="col-sm-6 col-lg-4 mb-4">
              <a href={image5} data-lightbox="image" data-title="Lemon">
                <img
                  decoding="async"
                  src={image5}
                  class="img-fluid rounded-3"
                />
              </a>
            </div>
            <div class="col-sm-6 col-lg-4 mb-4">
              <a href={image6} data-lightbox="image" data-title="Lemon">
                <img
                  decoding="async"
                  src={image6}
                  class="img-fluid rounded-3"
                />
              </a>
            </div>
            <div class="col-sm-6 col-lg-4 mb-4">
              <a href={image7} data-lightbox="image" data-title="Lemon">
                <img
                  decoding="async"
                  src={image7}
                  class="img-fluid rounded-3"
                />
              </a>
            </div>
            <div class="col-sm-6 col-lg-4 mb-4">
              <a href={image8} data-lightbox="image" data-title="Lemon">
                <img
                  decoding="async"
                  src={image8}
                  class="img-fluid rounded-3"
                />
              </a>
            </div>
            <div class="col-sm-6 col-lg-4 mb-4">
              <a href={image3} data-lightbox="image" data-title="Lemon">
                <img
                  decoding="async"
                  src={image3}
                  class="img-fluid rounded-3"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Gallery Section Exit --> */}
    </div>
  );
};

export default Gallery;
