import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const Result = () => {
  return (
    <>
      <p>Thank you for your message! We will get back to you shortly!</p>
    </>
  );
};

const Contact = () => {
  const form = useRef();

  const [result, showResult] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_s5woxgp",
        "template_bn5vtub",
        form.current,
        "UDqynssnARp4qIk4r"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
    showResult(true);
  };

  setTimeout(() => {
    showResult(false);
  }, 5000);

  return (
    <div class="contact">
      {/* <!-- banner Section start --> */}
      <section class="banner_section">
        <div class="container">
          <div class="banner-content">
            <h1>Contact Us</h1>
          </div>
        </div>
      </section>
      {/* <!-- banner section exit --> */}
      {/* <!--Contact Section Start--> */}
      <section class="contact_section">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 text-center pb-5">
              <h2 class="section-title">Get In Touch</h2>
              <p class="section-subtitle">Get a quote today! Beauty awaits!</p>
            </div>
            <div class="col-12 contact-form">
              <div class="row">
                <div class="col-lg-7 mb-5">
                  <form
                    ref={form}
                    action=""
                    onSubmit={sendEmail}
                    class="row g-3"
                  >
                    <div class="col-md-6 mb-4">
                      <input
                        name="fullName"
                        type="name"
                        class="form-control"
                        placeholder="Your Full Name *"
                        required=""
                      />
                    </div>
                    <div class="col-md-6 mb-4">
                      <input
                        type="email"
                        name="email"
                        class="form-control"
                        placeholder="Your Email *"
                        required=""
                      />
                    </div>
                    <div class="col-md-6 mb-4">
                      <input
                        type="address"
                        class="form-control"
                        name="address"
                        placeholder="Your Address *"
                        required=""
                      />
                    </div>
                    <div class="col-md-6 mb-4">
                      <input
                        type="number"
                        name="phone"
                        class="form-control"
                        placeholder="Your Phone *"
                        required=""
                      />
                    </div>
                    <div class="col-md-6 mb-4">
                      <input
                        type="text"
                        name="companyName"
                        class="form-control"
                        placeholder="Company"
                      />
                    </div>
                    <div class="col-md-6 mb-4">
                      <input
                        type="text"
                        name="subject"
                        class="form-control"
                        placeholder="Subject *"
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-4">
                      <textarea
                        rows="6"
                        class="form-control"
                        name="message"
                        placeholder="How may we assist?"
                        required
                      />
                    </div>
                    <div class="col-12">
                      <button type="submit" class="btn main-btn">
                        Send Message
                      </button>
                      <div className="row">{result ? <Result /> : null}</div>
                    </div>
                  </form>
                </div>
                <div class="col-lg-5 mb-5">
                  <div class="content-box ms-sm-5">
                    <ul class="info-box clearfix">
                      <li>
                        <i class="fas fa-phone-alt"></i>
                        <p>Any Questions? Call us</p>
                        <div>
                          <a href="">+2772 728 8729</a>
                        </div>
                      </li>
                      <li>
                        <i class="far fa-envelope-open"></i>
                        <p>Any Questions? Email us</p>
                        <div>
                          <a href="">info@starwoodandironcrafts.co.za</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--Contact Section Exit --> */}
    </div>
  );
};

export default Contact;
