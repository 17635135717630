import React from "react";
import AboutImg from "../../assets/images/about/1.jpg";
import AboutImg2 from "../../assets/images/about/about.jpg";
import AboutImg3 from "../../assets/images/about/2.jpg";

const About = () => {
  return (
    <div class="about">
      {/* <!-- banner Section start --> */}
      <section class="banner_section">
        <div class="container">
          <div class="banner-content">
            <h1>About Us</h1>
          </div>
        </div>
      </section>
      {/* <!-- banner section exit --> */}

      {/* <!--About Section start --> */}
      <section class="about_section">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 text-center pb-5">
              <h2 class="section-title">About Us</h2>
            </div>
          </div>
          <div class="row align-items-center py-5">
            <div class="col-lg-6 col-12 mb-4">
              <div class="me-lg-5">
                <img
                  decoding="async"
                  src={AboutImg3}
                  alt="about"
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="col-lg-6 col-12 mb-4">
              <div class="about-content">
                <div class="about-details">
                  <p class="section-subtitle" style={{ fontSize: "35px" }}>
                    Who <strong style={{ color: "#60CD12" }}>We</strong> Are
                  </p>
                  <p>
                    We specialize in Construction Services and Home Renovation
                    and Repairs, striving for 100% customer satisfaction
                    delivering top notch quality in our craft. Whether it's a
                    mold cleanup, a handyman repair, or a major construction
                    project, we are available for all of your home improvement
                    needs.
                  </p>
                  <p>
                    Between painting your exterior and remodeling your home,
                    there are endless possibilities for improving your property.
                    Finding the right company to take on those jobs is important
                    in order to make sure you get the results you wanted.
                  </p>
                  <p>
                    If you live in Cape Town, <b>Starwood and Iron Crafts</b> at
                    your service. From Renovations to Home Improvements, we are
                    here to help you bring to life the property of your dreams.
                    Our professionals are the perfect way to reimagine your
                    property from the inside out.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center py-5">
            <div class="col-lg-6 col-12 order-2 order-lg-1 mb-4 ">
              <div class="about-content">
                <div class="about-details">
                  <h2>Our Mission</h2>
                  <p>
                    Bringing you quality Service to your door step and To be the
                    best option for all your required services in line with our
                    expertise.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-12 order-1 order-lg-2 mb-4">
              <div class="me-lg-5">
                <img
                  decoding="async"
                  src={AboutImg2}
                  alt="about"
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
          <div class="row align-items-center py-5">
            <div class="col-lg-6 col-12 mb-4">
              <div class="me-lg-5">
                <img
                  decoding="async"
                  src={AboutImg}
                  alt="about"
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="col-lg-6 col-12 mb-4">
              <div class="about-content">
                <div class="about-details">
                  <p class="section-subtitle" style={{ fontSize: "35px" }}>
                    Our <strong style={{ color: "#60CD12" }}>Vision</strong>
                  </p>
                  <p>
                    With our core values and principles, we hope to help build a
                    better, productive and welcoming environment for each
                    resident, community and organization.
                  </p>
                  <br />
                  <h4>2030</h4>
                  <p>
                    We aim to create over 10thousand job opportunities for our
                    youth for a better tomorrow
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--About Section Exit --> */}
    </div>
  );
};

export default About;
