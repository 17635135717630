import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 100) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  return (
    <div>
      {/* <!-- Navbar Section Start --> */}
      <header id="full_nav">
        <div class="header">
          <div class="container">
            <nav class="navbar navbar-expand-lg">
              <Link to='/' class="navbar-brand">
                <div class="text-lg-end">
                  <span>
                    <b>
                      <h3>Starwood & Iron Crafts</h3>
                    </b>
                  </span>
                </div>
              </Link>
              {/* <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main-nav"
                        aria-controls="main-nav" aria-expanded="false" aria-label="Toggle navigation">
                        <!-- <span class="navbar-toggler-icon"></span> -->
                        <i class="fas fa-stream navbar-toggler-icon"></i>
                    </button> */}

              <div
                class={color ? "header" : "collapse navbar-collapse"}
                id="main-nav"
              >
                <ul class={click ? "nav-menu active" : "navbar-nav mx-auto"}>
                  <li class="nav-item">
                    <Link to='/' class="nav-link">
                      Home
                    </Link>
                  </li>

                  <li class="nav-item">
                    <Link to='/aboutus' class="nav-link">
                      About
                    </Link>
                  </li>

                  <li class="nav-item">
                    <Link to='/services' class="nav-link">
                      Services
                    </Link>
                  </li>

                  <li class="nav-item">
                    <Link to='/projects' class="nav-link">
                      Projects
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link to='/contacts' class="nav-link" href="/contacts">
                      Contact
                    </Link>
                  </li>
                </ul>
                <div className="hamburger" onClick={handleClick}>
                  {click ? (
                    <FaTimes size={20} style={{ color: "#fff" }} />
                  ) : (
                    <FaBars size={20} style={{ color: "#fff" }} />
                  )}
                </div>

                <div class="header_right">
                  <div class="text-lg-end">
                    <span>Call Us!</span>
                    <span class="phone_no">+2772-728-8729</span>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
      {/* <!-- Navbar Section Exit --> */}
    </div>
  );
};

export default Navbar;
