import React from "react";
import Image5 from "../../assets/images/products/product-1.jpg";
import Image6 from "../../assets/images/products/product-2.jpg";
import Image7 from "../../assets/images/products/product-3.jpg";
import Image8 from "../../assets/images/products/product-4.jpg";
import Image9 from "../../assets/images/testimonial/testimonial-1.png";
import Image10 from "../../assets/images/testimonial/qoutes.svg";
import Image11 from "../../assets/images/gallery/1.jpeg";
import Image12 from "../../assets/images/gallery/2.jpeg";
import Image13 from "../../assets/images/gallery/3.jpeg";
import Image14 from "../../assets/images/gallery/4.jpeg";
import Image15 from "../../assets/images/gallery/5.jpeg";
import Image16 from "../../assets/images/gallery/1.jpeg";
import { GiStrong } from "react-icons/gi";
import { GrLineChart } from "react-icons/gr";
import { FaGlobe, FaBriefcase } from "react-icons/fa";
import vid1 from "../../assets/videos/services/roof&trusses.mp4";
import vid2 from "../../assets/videos/services/ceiling.mp4";
import vid3 from "../../assets/videos/services/furniture.mp4";
import vid4 from "../../assets/videos/services/structure.mp4";
import vid5 from "../../assets/videos/services/partition.mp4";
import vid6 from "../../assets/videos/services/welding.mp4";
import vid7 from "../../assets/videos/services/home.mp4";
import vid8 from "../../assets/videos/services/office.mp4";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <section>
      {/* <!-- banner Section start --> */}
      <section class="banner_section">
        <div class="container">
          {/* <video autoPlay loop muted id="video">
            <source src={vid1} type="video/mp4" />
          </video> */}
          <div
            id="banner-carousel"
            class="carousel carousel-dark slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="carousel-caption">
                  <div class="banner-content">
                    <h1>100% Stable, Strong, Safe.</h1>
                    <h3>Think It, We Build It</h3>
                    <p style={{fontSize:'20px'}}>
                      Imagination should be used, not to escape reality, but to create it!
                    </p>
                    <Link to='/services' class="btn main-btn">
                      View Services
                    </Link>
                  </div>
                </div>
              </div>
              {/* <div class="carousel-item">
                <div class="carousel-caption">
                  <div class="banner-content">
                    <h1>100% Organic</h1>
                    <h3>Fresh & Natural Farm Food</h3>
                    <p>
                      The readable content of a page when looking at its layout
                      point of using of letters, as opposed.
                    </p>
                    <a href="/services" class="btn main-btn">
                      ALL PRoducts
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- banner section exit --> */}

      {/* <!-- Features Section start --> */}
      <section class="feature_section">
        <div class="container">
          <div class="row">
            <div class="col-6 col-lg-3 mb-5">
              <div class="card features-box">
                <div class="text-center">
                  <div class="features-icon-border">
                    <div class="features-icon">
                      <FaGlobe style={{ fontSize: "30px" }} />
                    </div>
                  </div>
                  <div class="features-text">
                    <h3>Experienced</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 col-lg-3 mb-5">
              <div class="card features-box">
                <div class="text-center">
                  <div class="features-icon-border">
                    <div class="features-icon">
                      <FaBriefcase style={{ fontSize: "30px" }} />
                    </div>
                  </div>
                  <div class="features-text">
                    <h3>Professional</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 col-lg-3 mb-5">
              <div class="card features-box">
                <div class="text-center">
                  <div class="features-icon-border">
                    <div class="features-icon">
                      <GiStrong style={{ fontSize: "30px" }} />
                    </div>
                  </div>
                  <div class="features-text">
                    <h3>Durable</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 col-lg-3 mb-5">
              <div class="card features-box">
                <div class="text-center">
                  <div class="features-icon-border">
                    <div class="features-icon">
                      <GrLineChart style={{ fontSize: "30px" }} />
                    </div>
                  </div>
                  <div class="features-text">
                    <h3>Fast & Reliable</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Features Section Exit --> */}

      {/* <!--About Section start --> */}
      <section class="landing_about_section">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-xl-5 col-lg-6 col-sm-8">
              <div class="about-content">
                <h2>Who We Are</h2>
                <div class="about-details">
                  <p class="fw-bold">
                  We specialize in Construction Services and Home Renovation
                    and Repairs, striving for 100% customer satisfaction
                    delivering top notch quality in our craft. Whether it's a
                    mold cleanup, a handyman repair, or a major construction
                    project, we are available for all of your home improvement
                    needs.
                  </p>
                  <p>
                  Between painting your exterior and remodeling your home,
                    there are endless possibilities for improving your property.
                    Finding the right company to take on those jobs is important
                    in order to make sure you get the results you wanted.
                  </p>
          

                  <Link to='/aboutus' class="btn main-btn">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--About Section Exit --> */}

      {/* <!--Product Section start --> */}
      <section class="landing_product_section">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 text-center pb-5">
              <h2 class="section-title">Our Best Selling Services</h2>
              <p class="section-subtitle">
              Change begins with you!
              </p>
            </div>
          </div>

          <div class="row mx-0">
            <div class="col-lg-3 col-sm-6 mb-5">
              <div class="card product-card">
                <div class="product-img">
                  {/* <img decoding="async" src={Image5} class="img-fluid" /> */}
                  <video autoPlay loop muted width="100%" height="100%">
                    <source src={vid1} type="video/mp4" />
                  </video>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <h3>Roof Trusses</h3>
                  {/* <span>$70 KG</span> */}
                </div>
                <div class="product-detail">
                  <Link to='/services'  class="btn main-btn">
                    View Service
                  </Link>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 mb-5">
              <div class="card product-card">
                <div class="product-img">
                  {/* <img decoding="async" src={Image5} class="img-fluid" /> */}
                  <video autoPlay loop muted width="100%" height='100%'>
                    <source src={vid2} type="video/mp4" />
                  </video>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <h3>Ceiling & Floor</h3>
                  {/* <span>$70 KG</span> */}
                </div>
                <div class="product-detail">
                  <Link to='/services' class="btn main-btn">
                    View Service
                  </Link>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 mb-5">
              <div class="card product-card">
                <div class="product-img">
                  {/* <img decoding="async" src={Image5} class="img-fluid" /> */}
                  <video autoPlay loop muted width="100%" height="100%">
                    <source src={vid3} type="video/mp4" />
                  </video>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <h3>Customized Furniture Designs</h3>
                  {/* <span>$70 KG</span> */}
                </div>
                <div class="product-detail">
                  <Link to='/services' class="btn main-btn">
                    View Service
                  </Link>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 mb-5">
              <div class="card product-card">
                <div class="product-img">
                  {/* <img decoding="async" src={Image5} class="img-fluid" /> */}
                  <video autoPlay loop muted width="100%" height="100%">
                    <source src={vid4} type="video/mp4" />
                  </video>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <h3>Customized Building Structure</h3>
                  {/* <span>$70 KG</span> */}
                </div>
                <div class="product-detail">
                  <Link to='/services' class="btn main-btn">
                    View Service
                  </Link>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 mb-5">
              <div class="card product-card">
                <div class="product-img">
                  {/* <img decoding="async" src={Image5} class="img-fluid" /> */}
                  <video autoPlay loop muted width="100%" height="100%">
                    <source src={vid5} type="video/mp4" />
                  </video>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <h3>Partitions & Renovations</h3>
                  {/* <span>$70 KG</span> */}
                </div>
                <div class="product-detail">
                  <Link to='/services' class="btn main-btn">
                    View Service
                  </Link>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 mb-5">
              <div class="card product-card">
                <div class="product-img">
                  {/* <img decoding="async" src={Image5} class="img-fluid" /> */}
                  <video autoPlay loop muted width="100%" height="100%">
                    <source src={vid6} type="video/mp4" />
                  </video>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <h3>Welding & Painting</h3>
                  {/* <span>$70 KG</span> */}
                </div>
                <div class="product-detail">
                  <Link to='/services' class="btn main-btn">
                    View Service
                  </Link>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 mb-5">
              <div class="card product-card">
                <div class="product-img">
                  {/* <img decoding="async" src={Image5} class="img-fluid" /> */}
                  <video autoPlay loop muted width="100%" height="100%">
                    <source src={vid7} type="video/mp4" />
                  </video>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <h3>Residential Cleaning</h3>
                  {/* <span>$70 KG</span> */}
                </div>
                <div class="product-detail">
                  <a href="https://www.thecia.co.za" target="_blank" class="btn main-btn">
                    View Service
                  </a>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 mb-5">
              <div class="card product-card">
                <div class="product-img">
                  {/* <img decoding="async" src={Image5} class="img-fluid" /> */}
                  <video autoPlay loop muted width="100%" height="100%">
                    <source src={vid8} type="video/mp4" />
                  </video>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <h3>Commercial Cleaning</h3>
                  {/* <span>$70 KG</span> */}
                </div>
                <div class="product-detail">
                  <a href="https://www.thecia.co.za" target="_blank" class="btn main-btn">
                    View Service
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--Product Section Exit --> */}

      {/* <!-- testimonial Section start --> */}
      {/* <section class="testimonial_section">
        <div class="container">
          <div class="row pb-5">
            <div class="col-12 text-center">
              <h2 class="section-title">Our Happy Customers</h2>
              <p class="section-subtitle">
                The Passage Experienced A Surge In Popularity During The 1960s
                When Again During The 90s As Desktop Publishers
              </p>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-xl-8 col-md-10">
              <div
                id="testimonial-slider"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <div class="carousel-indicators">
                  <button
                    data-bs-target="#testimonial-slider"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    data-bs-target="#testimonial-slider"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    data-bs-target="#testimonial-slider"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class="d-sm-flex row">
                      <div class="profile-box col-sm-5">
                        <img decoding="async" src={Image9} class="img-fluid" />
                      </div>
                      <div class="card  col-sm-7">
                        <div class="desc-box">
                          <p class="fst-italic">
                            Not only was customer support very fast, but the
                            design is very professional. Will definitely be
                            looking for new products in the future from this
                            author.
                          </p>
                          <div class="my-4">
                            <h4>Jecob Oramson</h4>
                            <p class="m-0 text-white">Happy Customers</p>
                          </div>
                          <img
                            decoding="async"
                            src={Image10}
                            class="float-end"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="d-sm-flex row">
                      <div class="profile-box col-sm-5">
                        <img decoding="async" src={Image9} class="img-fluid" />
                      </div>
                      <div class="card  col-sm-7">
                        <div class="desc-box">
                          <p class="fst-italic">
                            Not only was customer support very fast, but the
                            design is very professional. Will definitely be
                            looking for new products in the future from this
                            author.
                          </p>
                          <div class="my-4">
                            <h4>Jecob Oramson</h4>
                            <p class="m-0 text-white">Happy Customers</p>
                          </div>
                          <img
                            decoding="async"
                            src={Image10}
                            class="float-end"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="d-sm-flex row">
                      <div class="profile-box col-sm-5">
                        <img decoding="async" src={Image9} class="img-fluid" />
                      </div>
                      <div class="card  col-sm-7">
                        <div class="desc-box">
                          <p class="fst-italic">
                            Not only was customer support very fast, but the
                            design is very professional. Will definitely be
                            looking for new products in the future from this
                            author.
                          </p>
                          <div class="my-4">
                            <h4>Jecob Oramson</h4>
                            <p class="m-0 text-white">Happy Customers</p>
                          </div>
                          <img
                            decoding="async"
                            src={Image10}
                            class="float-end"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!--testimonial Section Exit --> */}

      {/* <!-- Gallery Section Start--> */}
      <section class="gallery_section" >
        <div class="container">
          <div class="row">
            <div class="col-12 text-center pb-5">
              <h2 class="section-title">Top Projects</h2>
              <p class="section-subtitle">
                Beauty from within and out
              </p>
            </div>
            <div class="col-sm-6 col-lg-4 mb-4">
              <a href={Image11} data-lightbox="image" data-title="Lemon">
                <img
                  decoding="async"
                  src={Image11}
                  class="img-fluid rounded-3"
                />
              </a>
            </div>
            <div class="col-sm-6 col-lg-4 mb-4">
              <a href={Image12} data-lightbox="image" data-title="Lemon">
                <img
                  decoding="async"
                  src={Image12}
                  class="img-fluid rounded-3"
                />
              </a>
            </div>
            <div class="col-sm-6 col-lg-4 mb-4">
              <a href={Image13} data-lightbox="image" data-title="Lemon">
                <img
                  decoding="async"
                  src={Image13}
                  class="img-fluid rounded-3"
                />
              </a>
            </div>
            <div className="clearfix"></div>
            <div class="col-sm-6 col-lg-4 mb-4">
              <a href={Image14} data-lightbox="image" data-title="Lemon">
                <img
                  decoding="async"
                  src={Image14}
                  class="img-fluid rounded-3"
                />
              </a>
            </div>
            <div class="col-sm-6 col-lg-4 mb-4">
              <a href={Image15} data-lightbox="image" data-title="Lemon">
                <img
                  decoding="async"
                  src={Image15}
                  class="img-fluid rounded-3"
                />
              </a>
            </div>
            <div class="col-sm-6 col-lg-4 mb-4">
              <a href={Image16} data-lightbox="image" data-title="Lemon">
                <img
                  decoding="async"
                  src={Image16}
                  class="img-fluid rounded-3"
                />
              </a>
            </div>

            <div class="col-sm-12 text-center mt-4">
              <Link to='/projects' class="btn main-btn">
                View More
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Gallery Section Exit --> */}
    </section>
  );
};

export default Home;
