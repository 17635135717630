import React from 'react'
import Navbar from '../common/navBar/Navbar'
import Error from '../components/error/Error'
import Footer from '../common/footer/Footer'

const Errors = () => {
  return (
    <>
    <Navbar/>
    <Error/>
    <Footer/>
    </>
  )
}

export default Errors