import React from "react";
import image1 from "../../assets/videos/services/roof&trusses.mp4";
import image2 from "../../assets/videos/services/ceiling.mp4";
import image3 from "../../assets/videos/services/furniture.mp4";
import image4 from "../../assets/videos/services/structure.mp4";
import Image5 from "../../assets/videos/services/partition.mp4";
import image6 from "../../assets/videos/services/welding.mp4";
import image7 from "../../assets/videos/services/home.mp4";
import image8 from "../../assets/videos/services/office.mp4";
import { Link } from "react-router-dom";

const Product = () => {
  return (
    <div class="product">
      {/* <!-- banner Section start --> */}
      <section class="banner_section">
        <div class="container">
          <div class="banner-content">
            <h1>Services</h1>
          </div>
        </div>
      </section>
      {/* <!-- banner section exit --> */}

      {/* <!--Product Section start --> */}
      <section class="product_section">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 text-center pb-5">
              <h2 class="section-title">Our Top Selling Services</h2>
              <p class="section-subtitle">
                Change begins with you!
              </p>
            </div>
          </div>
          <div class="row align-items-center py-5">
            <div class="col-xl-5 col-lg-6 col-12 mb-5">
              <div class="card border-0 me-lg-5">
                <video
                  autoPlay
                  loop
                  muted
                  decoding="async"
                  src={image1}
                  alt="products"
                ></video>
              </div>
            </div>
            <div class="col-xl-7 col-lg-6 col-12 mb-5">
              <div class="product-content">
                <h2>Roof Trusses</h2>
                <div class="product-details">
                  <p>
                  We offer high-quality roofing services at affordable prices. Whether a new roof or a roof repair our experience and know-how helps get the job done within your budget. From wood to steel Trusses, we make the strongest that will make you feel safe with your family at home in all circumstances.
                  </p>

                  <Link to='/contacts' class="btn main-btn">
                    Order Service
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div class="row align-items-center py-5">
            <div class="col-xl-7 col-lg-6 col-12 mb-5  order-2 order-lg-1">
              <div class="product-content">
                <h2>Ceiling & Floor</h2>
                <div class="product-details">
                  <p>
                  Think it, we build it! From simple to complicated designs, we cater for all. Either, it’s a simple repair or new installation, we got you covered.
                  </p>
                  

                  <Link to='/contacts' class="btn main-btn">
                    Order Service
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-xl-5 col-lg-6 col-12 mb-5 order-1 order-lg-2">
              <div class="card border-0 ms-lg-5">
              <video
                  autoPlay
                  loop
                  muted
                  decoding="async"
                  src={image2}
                  alt="products"
                ></video>
              </div>
            </div>
          </div>

          <div class="row align-items-center py-5">
            <div class="col-xl-5 col-lg-6 col-12 mb-5">
              <div class="card border-0 me-lg-5">
                <video
                  autoPlay
                  loop
                  muted
                  decoding="async"
                  src={image3}
                  alt="products"
                ></video>
              </div>
            </div>
            <div class="col-xl-7 col-lg-6 col-12 mb-5">
              <div class="product-content">
                <h2>Customized Furniture Designs</h2>
                <div class="product-details">
                  <p>
                  Provide door-to-door carpentry services at very affordable prices. From fitted indoor (wardrobes, kitchen fitted cardboards, repairs, tables, and chairs) to outdoor equipment & furniture (patios and cabins). When you come to Starwood & Iron Crafts, you can expect to have your project completed in no time. We are always thriving to exceed your standards. 
                  </p>
                  

                  <Link to='/contacts' class="btn main-btn">
                    Order Service
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div class="row align-items-center py-5">
            <div class="col-xl-7 col-lg-6 col-12 mb-5  order-2 order-lg-1">
              <div class="product-content">
                <h2>Customized Building Structure</h2>
                <div class="product-details">
                  <p>
                  Starwood & Iron Crafts' new construction services include the building of new structures from the ground up, whether it's a residential or commercial building. Our services typically involve a team of professionals such as engineers, construction managers, and builders who work together to design, plan, and execute your next construction project quickly and efficiently.
                  </p>
               

                  <a href="/contacts" class="btn main-btn">
                   Order Service
                  </a>
                </div>
              </div>
            </div>
            <div class="col-xl-5 col-lg-6 col-12 mb-5 order-1 order-lg-2">
              <div class="card border-0 ms-lg-5">
              <video
                  autoPlay
                  loop
                  muted
                  decoding="async"
                  src={image4}
                  alt="products"
                ></video>
              </div>
            </div>
          </div>

          <div class="row align-items-center py-5">
            <div class="col-xl-5 col-lg-6 col-12 mb-5">
              <div class="card border-0 me-lg-5">
                <video
                  autoPlay
                  loop
                  muted
                  decoding="async"
                  src={Image5}
                  alt="products"
                ></video>
              </div>
            </div>
            <div class="col-xl-7 col-lg-6 col-12 mb-5">
              <div class="product-content">
                <h2>Partitioning & Renovations</h2>
                <div class="product-details">
                  <p>
                  Our professional team offers everything you need for the perfect room addition including on-staff architects, interior designers, engineers and blueprint drafters. From house to office partitioning, we got you covered. 
                  </p>

                  <Link to='/contacts'class="btn main-btn">
                    Order Service
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div class="row align-items-center py-5">
            <div class="col-xl-7 col-lg-6 col-12 mb-5  order-2 order-lg-1">
              <div class="product-content">
                <h2>Welding & Painiting</h2>
                <div class="product-details">
                  <p>
                  The exterior and Interior of your home say a lot about it. And you only get one chance to make a first impression on guests and potential customers. Cosmetically speaking, nothing changes the feeling of a home more than an interior painting project.   A fresh coat of paint will not only bring your home back to life but will also help prevent UV damage to the exterior of the home and prevent rotting or further damage to wood shingles, clapboards and trim. We use top-of-the-line paints and our employees are well aware of how to combine their talents with the best materials to make indoor magic with a wide range of services. Patch Damaged Drywall, Apply Texture, Color Accents, Custom Designs, Wallpaper, Priming, Trim Painting, Wood Staining.
                  </p>
                  <p>
                  We also offer Welding services. We do steel aluminum and stainless welding, from large projects to small. We can build or repair your patio chair, weld I-Beams, Railings & Gates, Flashing, Hood Vents & Brackets, Signs & Cutouts, create counter supports and more. FROM FRICTION WELDING MACHINES TO PARTS, WE’VE GOT IT MADE
                  </p>


                  <Link to='/contacts'class="btn main-btn">
                   Order Service
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-xl-5 col-lg-6 col-12 mb-5 order-1 order-lg-2">
              <div class="card border-0 ms-lg-5">
              <video
                  autoPlay
                  loop
                  muted
                  decoding="async"
                  src={image6}
                  alt="products"
                ></video>
              </div>
            </div>
          </div>

          <div class="row align-items-center py-5">
            <div class="col-xl-5 col-lg-6 col-12 mb-5">
              <div class="card border-0 me-lg-5">
                <video
                  autoPlay
                  loop
                  muted
                  decoding="async"
                  src={image7}
                  alt="products"
                ></video>
              </div>
            </div>
            <div class="col-xl-7 col-lg-6 col-12 mb-5">
              <div class="product-content">
                <h2>Residential Cleaning</h2>
                <div class="product-details">
                  <p>
                  Imagine having a clean home as often as you like without doing all the work. What would you do with the extra time if you didn't need to clean your home each week? When you partner with the CIA, we can help you enjoy more free time and a cleaner home with a residential cleaning plan that fits your schedule and cleaning needs.
                  </p>
                

                  <a href="https://www.thecia.co.za" class="btn main-btn" target="blank">
                   Order Service
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="row align-items-center py-5">
            <div class="col-xl-7 col-lg-6 col-12 mb-5  order-2 order-lg-1">
              <div class="product-content">
                <h2>Commercial Cleaning</h2>
                <div class="product-details">
                  <p>
                  From offices to AirBnBs, you can rely on the CIA to help you channel your energy to highly productive activities that keeps the business growing and evolving while we take care of the dirt and filth for you. 
                  </p>
                  
                  <a href="https://www.thecia.co.za" target="blank" class="btn main-btn">
                    Order Service
                  </a>
                </div>
              </div>
            </div>
            <div class="col-xl-5 col-lg-6 col-12 mb-5 order-1 order-lg-2">
              <div class="card border-0 ms-lg-5">
              <video
                  autoPlay
                  loop
                  muted
                  decoding="async"
                  src={image8}
                  alt="products"
                ></video>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--Product Section Exit --> */}
    </div>
  );
};

export default Product;
