import React from 'react'
import Navbar from '../common/navBar/Navbar'
import Footer from '../common/footer/Footer'
import About from '../components/about/About'

const Abouts = () => {
  return (
    <div>
        <Navbar />
        <About />
        <Footer/>
    </div>
  )
}

export default Abouts