import React from 'react'
import Navbar from '../common/navBar/Navbar'
import Footer from '../common/footer/Footer'
import Gallery from '../components/gallery/Gallery'

const Projects = () => {
  return (
    <div>
        <Navbar/>
        <Gallery/>
        <Footer/>
    </div>
  )
}

export default Projects